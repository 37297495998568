import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ZZZBangbooIntro: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Bangboo - Intro</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_agents.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details ">
          <h1>Bangboo - Intro</h1>
          <h2>
            Bangboo from Zenless Zone Zero explained - their lore, skills and
            how they work!
          </h2>
          <p>
            Last updated: <strong>12/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Bangboo lore" />
        <StaticImage
          src="../../../images/zzz/guides/info_bangboo.webp"
          alt="Guide"
        />
        <h5>Small body, big helper!</h5>
        <p>
          When you walk down the street and see a small figure making "enn enn"
          noises, don't be alarmed. It's just a Bangboo — an intelligent device
          unique to New Eridu. Bangboo are only around knee-height in size, but
          they're indispensable helpers in New Eridu's daily operation. After
          years of research and development, Bangboo are widely and
          comprehensively used in many scenarios. Many factories also provide
          customization services to allow for specific Bangboo functions for
          different groups of people.
        </p>
        <h5>What can Bangboo be used for?</h5>
        <p>
          Bangboo were originally created to assist New Eridu residents with
          evacuation in Hollow disasters. When a Hollow occurs in New Eridu's
          residential areas, Bangboo will immediately receive disaster
          information and evacuation guidance from the public network, and help
          people around them avoid the hazard-prone areas.
        </p>
        <p>
          Now, thanks to technological advancement and emerging diverse needs,
          Bangboo have evolved from simple helper intelligent devices for
          disaster avoidance to more comprehensive and versatile intelligent
          individuals. Some pro version Bangboo even go deep into Hollows with
          Investigators and help with various operations.
        </p>
        <SectionHeader title="Bangboo & Combat" />
        <p>
          If you ever played Honkai Impact 3, then{' '}
          <strong>Bangboo are really similar to ELFs</strong> - they are the 4th
          team member who supports the currently active character with both
          active skills and buffs. Similar to Agents, Bangboo come in two ranks
          - <strong className="s-rank">S-rank</strong> and{' '}
          <strong className="a-rank">A-rank</strong>.
        </p>
        <h5>How to obtain Bangboo?</h5>
        <p>
          You can pull them from the Bangboo Oriented Signal Search. You can
          only use <strong>Boopons</strong> on this banner -{' '}
          <strong>Boopons</strong> can be obtained from missions, events and
          various modes in Hollow Deep Dive Zone and can't be bought using
          real-life currency.
        </p>
        <h5>Bangboo Skills</h5>
        <p>Each Bangboo has 3 skills:</p>
        <ul>
          <li>
            <strong>Active skill</strong> - codenamed A - that is used
            automatically by the Bangboo with a set cooldown,
          </li>
          <li>
            <strong>Additional Ability</strong> - codenamed B - a passive on
            field effect thats active only if you fulfill its conditions,
          </li>
          <li>
            <strong>Bangboo Chain Attack</strong> - codenamed C - an additional
            chain attack you can use.
          </li>
        </ul>
        <h5>Bangboo Leveling</h5>
        <p>
          You can level your Bangboo just like you can level the Agents and this
          will increase their base stats (HP, ATK and DEF) and A and C skill
          levels.
        </p>
        <h5>Bangboo Ascension</h5>
        <p>
          Bangboo can be ascended and this will improve their skills and you can
          ascend each Bangboo up to 5 times.
        </p>
        <p>
          Overall, Skill A and C can be leveled up to <strong>level 10</strong>{' '}
          (4 from dupes and 5 from levels) while Skill B can only be leveled
          using the dupes, so it's capped at <strong>level 5</strong>.
        </p>
        <h5>Bangboo Chain Attacks</h5>
        <p>
          Most Bangboos have chain attacks. With a full party of 3, these chain
          attacks can be performed on the 3rd part of the chain. Although
          typically only Boss type enemies provide you with the 3rd chain
          attack, there are a few more scenarios where you can still utilize
          your Bangboo’s chain attack:
        </p>
        <ul>
          <li>
            Stunning multiple enemies in succession will continue your chain
            order. You’ll need to stun enemies with the chain attacks themselves
            to reach the 3rd part of your chain attack.
          </li>
          <li>
            Bringing 2 or 1 agent in your party will ensure you always have your
            Bangboo as a chain attack option. In fact, with just 1 agent, your
            agent will still perform their chain attack after your Bangboo.
          </li>
        </ul>
        <p>
          Bangboo chain attacks take time and some Bangboo chain attacks take
          longer than others. The longer the Bangboo’s chain attack takes, the
          less time the enemy will be stunned for your DPS character. Chain time
          is provided in the linked guide video.
        </p>
        <h5>Bangboo Mechanics</h5>
        <p>Bangboos have many interesting mechanics to keep in mind:</p>
        <ul>
          <li>
            Exclamation marks will appear over their head once they are ready to
            perform their active skill, after which they will perform a goofy
            animation and then perform their active skill.
          </li>
          <li>
            While Bangboos contribute towards a monster’s stun gauge and anomaly
            build up, they cannot trigger the stun or the Anomaly Effect (Shock,
            Assault, etc).
          </li>
          <li>
            Since Bangboos do not have an Anomaly Proficiency stat, they do not
            increase or decrease the damage of the Anomaly Effect (Shock,
            Assault, etc).
          </li>
        </ul>
        <h5>Which Bangboo Should You Choose?</h5>
        <p>
          Bangboos have a variety of functions. Some deal more damage, some
          apply more anomaly, and others provide support like grouping, energy
          and healing. To find out which Bangboo you should choose as well as a
          showcase of all of them, check out the guide video!
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="GV0BKl7EbkM" />
          </Col>
        </Row>
        <SectionHeader title="Bangboo list" />
        <p>
          If you want to check the list of currently available Bangboo and their
          skills, take a look at this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Bangboo list"
            link="/zenless/bangboo"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_bangboo.png"
                alt="Bangboo list"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZBangbooIntro;

export const Head: React.FC = () => (
  <Seo
    title="Bangboo Intro | Zenless Zone Zero | Prydwen Institute"
    description="Bangboo from Zenless Zone Zero explained - their lore, skills and how they work!"
    game="zzz"
  />
);
